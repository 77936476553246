import gsap from 'gsap'
import Siema from 'siema'
import { qs, qsa } from '../utils/lib'
export default class Slider {
    constructor(el) {
    	this.el = el
    	this.click = false
    	this.drag = false
    	this.movement = 0
    	this.current = 0
        this.slides = qsa('.slider_track_item',this.el)
    	this.currentSlide = qsa('.slider_track_item',this.el)[this.current]
    	this.currentImage = qs('img', this.currentSlide)
    	this.currentText = qs('.slider_track_item_text', this.currentSlide)
        this.threshold = 400
        this.is_mobile = window.innerWidth < 768
        this.indicators_container = this.el.parentNode.querySelector('.slider_indicators')
        this.createIndicators()
        this.indicators = qsa('.indicator_item',this.indicators_container)
        this.indicators[this.current].classList.add('active')
    }
    init() {
        if (this.is_mobile) {
            this.threshold = 200
        }
        this.siema = new Siema({
            selector: '.siema',
            duration: 1200,
            easing: 'cubic-bezier(.7,.69,.65,.99)',
            perPage: 1,
            startIndex: 0,
            draggable: true,
            multipleDrag: true,
            threshold: this.threshold,
            loop: false,
            rtl: false,
            onInit: () => {
                window.requestAnimationFrame(() => {
                    this.parallax(this.slides)
                })
            },
            onChange: () => {
            	this.changeActive()
            }
        })

        this.siema.selector.addEventListener('mousedown', () => {
        	this.click = true
        })
        this.siema.selector.addEventListener('mousemove', () => {
        	if (this.click) {
        		this.drag = true
        	}
        })
        this.siema.selector.addEventListener('mouseup', () => {
        	this.click = false
        	this.resetSlide()
        })        
        this.indicators.forEach((ind,index) => {
            ind.addEventListener('click', () => {
                this.siema.goTo(index)
            })
        })
    }

    createIndicators(){
        this.slides.forEach((sl,index) => {
            let indicator = document.createElement('span')
            this.indicators_container.appendChild(indicator)
            indicator.classList.add('indicator_item')
            if (index == this.current) {
                indicator.classList.add('active')                
            }
        })
    }


    parallax(slides){
        this.slides.forEach((slide) => {
            const image = slide.querySelector('img')
            const pos = slide.getBoundingClientRect()
            const center = window.innerWidth / 2
            const el_center = pos.x + pos.width / 2
            const diff = el_center - center
            gsap.to(image,{
                x: -(diff / 10)
            })
        })  
        window.requestAnimationFrame(() => {this.parallax(this.slides)})
    }
    changeActive(){
        this.current = Math.round(this.siema.currentSlide)    	
    	this.currentSlide = this.el.querySelectorAll('.slider_track_item')[this.current]
    	this.currentImage = this.currentSlide.querySelector('img')
    	this.currentText = this.currentSlide.querySelector('.slider_track_item_text')
        this.indicators.forEach((ind,index) => {
            if (index == this.current) {
                ind.classList.add('active')
            } else {
                ind.classList.remove('active')
            }
        })    	
    }
    resetSlide(){
    	gsap.to(this.currentText,{
    		x : 0
    	})
        gsap.to(this.currentImage,{
            x : 0
        })        
    }
}