export default [
	{
		"image": "",
		"text": "Cavi",
		"active": 0,
		"options": [{"text":"Base","price":45,"image":""}]
	}, 
	{
		"image": "",
		"text": "Base Cottura",
		"active": 0,
		"options": [{"text":"nero","price":37,"image":""},{"text":"bianco","price":21,"image":""},{"text":"rame","price":10,"image":""}]
	}, 
	{
		"image": "",
		"text": "Cottura",
		"active": 0,
		"options": [{"text":"nero","price":15,"image":""},{"text":"bianco","price":37,"image":""}]
	}, 
	{
		"image": "",
		"text": "Controlli",
		"active": 0,
		"options": [{"text":"nero","price":11,"image":""},{"text":"rosso","price":30,"image":""}]
	} 
]