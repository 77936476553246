import { qs } from "../utils/lib"
export default class HpPopup{
	constructor(el){
		this.popup = el
		this.close_btn = this.popup.querySelector('.variant_popup_header_close')
        this.init()
	}
	init(){
		this.close_btn.addEventListener('click',this.close.bind(this))
	}
	close(){
        this.popup.classList.remove('open')
	}	
}
